import Banner from 'components/Banner';
import Button from 'components/Button';
import { cdnRoot } from 'config';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './style.scss';

const DjBackline = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) setLoaded(true);
    }, [loaded]);

    return (
        <div className="page" id="dj-backline">

            <Helmet>
                <title>Tier 33 - DJ Backline</title>
                <meta name='description' content='Tier 33 - DJ Backline' />
                <meta property="og:title" content="Tier 33 - DJ Backline" />
                <meta property="og:description" content="Tier 33 - DJ Backline" />
            </Helmet>

            <Banner title="DJ Backline" bg={`${cdnRoot}/images/pages/dj-backline/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/dj-backline/banner_thumb.jpg`} />

            <div className="content pad-bottom">

                <div className="blurb darker">
                    <p>
                        We offer a large variety of the leading DJ equipment on the market. Nearly every make & model from club standard DJ setups, to
                        analog & rotary mixers for the audiophiles.
                    </p>
                    <p>Pioneer, Xone, Technic, and E&S are a few of the brands you’ll find in our inventory, we only work with the best!</p>
                    <p>Our DJ Backline inventory is always being updated, if you don’t see what you’re looking for, contact us today and we will accomodate your needs! </p>
                </div>

                <ul className="items pad-top-half">

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/dj-backline/Pioneer CDJ2000 IMAGE.png`} />
                        </div>
                        <div className="title">
                            Pioneer CDJ-2000NXS2
                        </div>
                        <div className="description">
                            The industry standard CDJ for professional djs. It can read CD, thumb drive, Rekordboxx, SD Memory Card, and also can use WiFi to communicate with an iPhone, Android, and other wireless music sources.
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/dj-backline/TECHNIC IMAGE.png`} />
                        </div>
                        <div className="title">
                            Technic 1200
                        </div>
                        <div className="description">
                            The Technics 1200 has been the go to turntable for professional DJ’s since
                            the late 1970’s. With its direct drive belt technology, no other turntable comes
                            close to the responsiveness and tactile touch of the 1200. All of our
                            1200’s are externally-grounded and Traktor/Serato ready.
                            (Slip mats & Stylus not included in rental)
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/dj-backline/Pioneer DJM900 IMAGE.png`} />
                        </div>
                        <div className="title">
                            Pioneer DJM-900NXS
                        </div>
                        <div className="description">
                            The club standard DJ Mixer. The DJM-900NXS is the next level in creativity,
                            delivering excellent connectivity, up-to-date FX and mind-blowing performance
                            features to make it the centrepiece of any professional DJ setup.
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/dj-backline/Pioneer DJM-S9 IMAGE.png`} />
                        </div>
                        <div className="title">
                            Pioneer DJM-S9
                        </div>
                        <div className="description">
                            Pioneer’s first two channel battle mixer for Serato DJ Pro.
                            Featuring a fully configurable Magvel Fader Pro, Performance Pads,
                            customisable FX buttons. The tough DJM-S9 is build to
                            withstand the most energetic scratch performances.
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/dj-backline/Xone 96 IMAGE.png`} />
                        </div>
                        <div className="title">
                            Xone-96
                        </div>
                        <div className="description">
                            An uncompromising analogue DJ mixer, destined to be the heart of your creativity,
                            Xone:96 takes the legendary soul of the acclaimed Xone:92 and redelivers it, enhanced,
                            and with state-of-the-art digital connectivity. Xone:96 allows you to connect your whole rig,
                            from laptops and turntables, to pedals, synths and drum machines.
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/dj-backline/E&S DJR400 IMAGE.png`} />
                        </div>
                        <div className="title">
                            E&amp;S DJR400
                        </div>
                        <div className="description">
                            Built by French audio conniseur, Jerome Barbe, the E&S is the first “travel” rotary mixer
                            of its kind. Featuring a very powerful integrated crossover, 3 phono & 3 digital inputs,
                            FX sends on all 4 channels, and more, These custom mixers pack a punch like
                            no other on the market. Deep House legend Kerri Chandler describes it as
                            “just like driving a portable Lamborghini”.
                        </div>
                    </li>

                </ul>

                <div className="sections">

                    <div className="section">
                        <div className="title">
                            Additional DJ Services
                        </div>
                        <ul className="list">
                            <li>Slipmats</li>
                            <li>Turntable Isolation Slabs</li>
                            <li>Needles</li>
                            <li>DJ Booths</li>
                            <li>Serato Box / Control Vinyl</li>
                            <li>Microphones</li>
                            <li>FX Modules</li>
                            <li>Monitors</li>
                            <li>CDJ Stands</li>
                            <li>Cables</li>
                        </ul>
                    </div>

                </div>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div>
    );
};

export default DjBackline;
