import Banner from 'components/Banner';
import Button from 'components/Button';
import { cdnRoot } from 'config';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import './style.scss';

const AudioQSC = () => {

    return (
        <div className="page" id="audio-qsc">

            <Helmet>
                <title>Tier 33 - Audio - QSC Speaker Rentals</title>
                <meta name='description' content='Tier 33 - QSC Speaker Rentals - QSC is a global leader in the design & manufacture of professional audio system solutions. If you’re looking for high quality QSC Powered sound rental, we’ve got you covered!' />
                <meta property="og:title" content="Tier 33 - Audio - QSC Speaker Rentals" />
                <meta property="og:description" content="Tier 33 - QSC Speaker Rentals - QSC is a global leader in the design & manufacture of professional audio system solutions. If you’re looking for high quality QSC Powered sound rental, we’ve got you covered!" />
            </Helmet>

            <Banner title="QSC Speaker Rental" bg={`${cdnRoot}/images/pages/audio/qsc/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/audio/qsc/banner_thumb.jpg`} />

            <div className="content">

                <div className="blurb darker">
                    <p>
                        QSC is a global leader in the design & manufacture of
                        professional audio system solutions. If you’re looking for
                        high quality QSC Powered sound rental, we’ve got you covered!
                    </p>
                </div>

                <ul className="items pad">

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/audio/qsc/QSC K10.2 photo.png`} />
                        </div>
                        <div className="brand-logo">
                            <img src={`${cdnRoot}/images/pages/audio/qsc_logo.svg`} />
                        </div>
                        <div className="title">
                            K10.2
                        </div>
                        <div className="description">
                            2000W PA Speaker with a 10” Low Frequency Driver and a 1.4” High Frequency Driver
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/audio/qsc/QSC K12.2 photo.png`} />
                        </div>
                        <div className="brand-logo">
                            <img src={`${cdnRoot}/images/pages/audio/qsc_logo.svg`} />
                        </div>
                        <div className="title">
                            K12.2
                        </div>
                        <div className="description">
                            2000W PA Speaker with a 12” Low Frequency Driver and a 1.4” High Frequency Driver
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/audio/qsc/QSC KW122 photo.png`} />
                        </div>
                        <div className="brand-logo">
                            <img src={`${cdnRoot}/images/pages/audio/qsc_logo.svg`} />
                        </div>
                        <div className="title">
                            KW122
                        </div>
                        <div className="description">
                            1000W 12” KW Series PA Speaker with a 12” Low Frequency Driver and a 1.75” High Frequency Driver
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/audio/qsc/QSC K-Sub Photo.png`} />
                        </div>
                        <div className="brand-logo">
                            <img src={`${cdnRoot}/images/pages/audio/qsc_logo.svg`} />
                        </div>
                        <div className="title">
                            QSC K-SUB
                        </div>
                        <div className="description">
                            1000W Dual-Powered 12” Subwoofer
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/audio/qsc/QSC KW181 Photo.png`} />
                        </div>
                        <div className="brand-logo">
                            <img src={`${cdnRoot}/images/pages/audio/qsc_logo.svg`} />
                        </div>
                        <div className="title">
                            QSC KW181
                        </div>
                        <div className="description">
                            3600W 18” Subwoofer
                        </div>
                    </li>

                </ul>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div>
    );
};

export default AudioQSC;
