import Banner from "components/Banner";
import Button from "components/Button";
import { pageCarouselSettings } from "components/Carousel/carouselSettings";
import LazyLoadImg from "components/LazyLoadImg";
import { cdnRoot } from "config";
import React from 'react';
import { Helmet } from "react-helmet-async";
import Slider from 'react-slick';
import { isMobile } from '../../utils/utils';
import './style.scss';

const Video = () => {

    return (
        <div className="page" id="video">

            <Helmet>
                <title>Tier 33 - Video</title>
                <meta name='description' content="Elevate your event to the next level with video!

                Tier 33 offers a full array of video products and services for all
                types of events such as banquets, conferences, receptions, viewing parties,
                live concerts & more. Our technicians have worked with the most prominent
                video entertainment companies in Philadelphia and will happily advise
                you on what you need and how to get it done. Contact us today to see
                how we can help set your event apart from the rest!
                " />
                <meta property="og:title" content="Tier 33 - Video" />
                <meta property="og:description" content="Elevate your event to the next level with video!

Tier 33 offers a full array of video products and services for all
types of events such as banquets, conferences, receptions, viewing parties,
live concerts & more. Our technicians have worked with the most prominent
video entertainment companies in Philadelphia and will happily advise
you on what you need and how to get it done. Contact us today to see
how we can help set your event apart from the rest!
" />
            </Helmet>

            <Banner title="Video" bg={`${cdnRoot}/images/pages/video/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/video/banner_thumb.jpg`} />

            <div className="content">

                <div className="blurb darker">
                    <p>
                        Elevate your event to the next level with video!
                    </p>

                    <p>
                        Tier 33 offers a full array of video products and services for all
                        types of events such as banquets, conferences, receptions, viewing parties,
                        live concerts & more. Our technicians have worked with the most prominent
                        video entertainment companies in Philadelphia and will happily advise
                        you on what you need and how to get it done. Contact us today to see
                        how we can help set your event apart from the rest!
                    </p>
                </div>

                <Slider settings={pageCarouselSettings} autoplay={true} autoplaySpeed={4000} speed={2000} pauseOnHover={false} slidesToShow={isMobile() ? 1 : 2} dots={false} arrows={false}>
                    <div className="carousel-img"><LazyLoadImg img={`${cdnRoot}/images/pages/video/Video Photo 1.jpg`} thumb={`${cdnRoot}/images/pages/video/Video Photo 1_thumb.jpg`} /></div>
                    <div className="carousel-img"><LazyLoadImg img={`${cdnRoot}/images/pages/video/Video Photo 2.JPG`} thumb={`${cdnRoot}/images/pages/video/Video Photo 2_thumb.jpg`} /></div>
                    <div className="carousel-img"><LazyLoadImg img={`${cdnRoot}/images/pages/video/Video Photo 3.png`} thumb={`${cdnRoot}/images/pages/video/Video Photo 3_thumb.png`} /></div>
                </Slider>

                <div className="sections">

                    <div className="section services">
                        <div className="title">
                            Video Rentals &amp; Services
                        </div>
                        <ul className="list">
                            <li>Projectors &amp; Screens</li>
                            <li>Laptops, Touchpads, iPads</li>
                            <li>Indoor/Outdoor LED Video Walls</li>
                            <li>Cameras &amp; Switchers</li>
                            <li>Display Monitors &amp; TVs</li>
                            <li>Custom Fabrication &amp; Install</li>
                        </ul>
                    </div>

                </div>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div>
    );
};

export default Video;
