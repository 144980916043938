import React, { useState } from 'react';

const LazyLoadImg = ({ img, thumb, alt = "" }) => {
    const [thumbLoaded, setThumbLoaded] = useState(false);
    const [imgLoaded, setImgLoaded] = useState(false);

    const onThumbLoaded = (e) => {
        setThumbLoaded(true);
    }
    const onImgLoaded = (e) => {
        setImgLoaded(true);
    }

    return (
        <div className={"img-lazy " + (imgLoaded ? 'loaded' : '')}>
            {thumb && <div className={"img thumb" + (thumbLoaded ? ' loaded' : '')}><img src={thumb} alt={alt} onLoad={onThumbLoaded} /></div>}
            {img && <div className="img full"><img src={img} loading="lazy" alt={alt} onLoad={onImgLoaded} /></div>}
        </div>
    );
};

export default LazyLoadImg;
