const FORM_URL = process.env.CONTACT_FORM_ENDPOINT || "https://huufdnxqtjhwit34lzhohbuhoa0rgwgh.lambda-url.us-east-1.on.aws/";

export const sendEmail = async (name, email, subject, message) => {
    try {
        //console.log(`Sending email...`, name, email, subject, message);

        let r = await fetch(FORM_URL, {
            method: 'POST',
            //mode: 'cors',
            //credentials: 'omit', //same-origin',
            headers: {
                "Content-Type": "application/json",
            },
            //redirect: "follow", // manual, *follow, error
            //referrerPolicy: "no-referrer",
            body: JSON.stringify({ name, email, subject, message }),
        });

        return r;
    } catch (e) {
        throw e;
    }
};

export const throttle = (func, delay) => {
    let prev = 0;
    return (...args) => {
        let now = new Date().getTime();
        if (now - prev > delay) {
            prev = now;
            return func(...args);
        }
    }
};

export function isEmail(str) {
    return str ? /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(str) : false;
};

export const isMobile = () => {
    return window.innerWidth < 1024;
};