import Banner from 'components/Banner';
import Button from 'components/Button';
import { cdnRoot } from 'config';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import './style.scss';

const EventProduction = () => {

    return (
        <div className="page" id="event-production">

            <Helmet>
                <title>Tier 33 - Event Production</title>
                <meta name='description' content='It takes an army of people to build a successful event.
                        Tier 33 can provide event production equipment rentals,
                        for concerts, corporate events, art galleries, and more.

                        With nearly a decade of experience in the industry, we have built
                        a powerful network of designers, technicians, laborers and resources
                        which leaves our team limitless. From pre-planning and design, to
                        staffing & logistics, to event production on the day, it is our pleasure
                        to aid in the success of your event from start to finish.

                        If you are in need of audio, dj backline, lighting, video,
                        staging, special FX, staffing, or overall management, Tier 33
                        has you covered. Contact us for a free consulation.
                        ' />

                <meta property="og:title" content="Tier 33 - Event Production" />
                <meta property="og:description" content="It takes an army of people to build a successful event.
                        Tier 33 can provide event production equipment rentals,
                        for concerts, corporate events, art galleries, and more.

                        With nearly a decade of experience in the industry, we have built
                        a powerful network of designers, technicians, laborers and resources
                        which leaves our team limitless. From pre-planning and design, to
                        staffing & logistics, to event production on the day, it is our pleasure
                        to aid in the success of your event from start to finish.

                        If you are in need of audio, dj backline, lighting, video,
                        staging, special FX, staffing, or overall management, Tier 33
                        has you covered. Contact us for a free consulation.
                        " />
            </Helmet>

            <Banner title="Event Production" bg={`${cdnRoot}/images/pages/event-production/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/event-production/banner_thumb.jpg`} />

            <div className="content">

                <div className="blurb">
                    <p>
                        It takes an army of people to build a successful event.
                        Tier 33 can provide event production equipment rentals,
                        for concerts, corporate events, art galleries, and more.
                    </p>

                    <p>
                        With nearly a decade of experience in the industry, we have built
                        a powerful network of designers, technicians, laborers and resources
                        which leaves our team limitless. From pre-planning and design, to
                        staffing & logistics, to event production on the day, it is our pleasure
                        to aid in the success of your event from start to finish.
                    </p>

                    <p>
                        If you are in need of audio, dj backline, lighting, video,
                        staging, special FX, staffing, or overall management, Tier 33
                        has you covered. Contact us for a free consulation.
                    </p>

                </div>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div>
    );
};

export default EventProduction;
