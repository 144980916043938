import LazyLoadImg from 'components/LazyLoadImg';
import { cdnRoot } from "config";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const SiteHeader = (activePage: any = undefined) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (forceVal) => {
        //let newVal = typeof forceVal != 'undefined' ? forceVal : !menuOpen;
        setMenuOpen(typeof forceVal != 'undefined' ? forceVal : !menuOpen);
    }

    return (
        <div id="site-header">

            <div className="logo">
                <Link to="/" onClick={() => toggleMenu(false)}>
                    <LazyLoadImg img={`${cdnRoot}/images/logos/logo_white.png`} thumb={`${cdnRoot}/images/logos/logo_white.png`} alt="Tier 33 Logo" />
                </Link>
            </div>

            <div className={"menu " + (menuOpen ? 'open' : 'closed')}>

                <div className="control" onClick={() => toggleMenu(!menuOpen)}>
                    <div className="image">{!menuOpen ? <img src={`${cdnRoot}/images/menu.svg`} /> : <img src={`${cdnRoot}/images/close_x.svg`} />}</div>
                </div>

                <div className="overlay">

                    <ul className="items top">
                        <li><Link to={"/about"} onClick={() => toggleMenu(false)}>About</Link></li>
                        <li><Link to={"/clients"} onClick={() => toggleMenu(false)}>Clients</Link></li>
                        <li><Link to={"/gallery"} onClick={() => toggleMenu(false)}>Gallery</Link></li>
                        <li><Link to={"/contact"} onClick={() => toggleMenu(false)}>Contact Us</Link></li>
                    </ul>

                    <ul className="items bottom">
                        <li><Link to={"/audio"} onClick={() => toggleMenu(false)}>Audio <img className="arrow" src={`${cdnRoot}/images/down_arrow.svg`} /></Link>
                            <div className={"sub-menu"}>
                                <Link to={"/audio/qsc"}>QSC</Link>
                                <Link to={"/audio/danley"}>Danley</Link>
                            </div>
                        </li>
                        <li><Link to={"/dj-backline"} onClick={() => toggleMenu(false)}>DJ Backline</Link></li>
                        <li><Link to={"/lighting"} onClick={() => toggleMenu(false)}>Lighting</Link></li>
                        <li><Link to={"/video"} onClick={() => toggleMenu(false)}>Video</Link></li>
                        <li><Link to={"/event-production"} onClick={() => toggleMenu(false)}>Event Production</Link></li>
                    </ul>

                </div>
            </div>
        </div>
    );
};

export default SiteHeader;
