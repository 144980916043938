import Button from 'components/Button';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './style.scss';

const PageNotFound = () => {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) setLoaded(true);
    }, [loaded]);

    return (
        <div className="page" id="page-not-found">

            <Helmet>
                <title>Tier 33 - Page Not Found</title>
                <meta name='description' content='Page Not Found' />

                <meta property="og:title" content="Tier 33 - Page Not Found" />
                <meta property="og:description" content="Page Not Found" />
            </Helmet>

            <div className="content pad">
                <div className="blurb">
                    <h1>Oops!</h1>
                    <h2>Page not found.</h2>
                    <br />
                </div>

                <div className="contact-banner">
                    <Button to="/" type="outline red">Return Home</Button>
                </div>
            </div>

        </div>
    );
};

export default PageNotFound;
