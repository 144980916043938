import Banner from 'components/Banner';
import Button from 'components/Button';
import { homeCarouselSettings } from 'components/Carousel/carouselSettings';
import LazyLoadImg from 'components/LazyLoadImg';
import { cdnRoot } from "config";
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './style.scss';


const Home = () => {

    const [cardStates, setCardStates] = useState({
        'audio': false,
        'dj-backline': false,
        'lighting': false,
        'video': false,
        'event-production': false
    });

    const onCardTouch = (e, id) => {
        let st = cardStates;
        st[id] = true;
        setCardStates(st);
    }

    const onCardTouchEnd = (e, id) => {
        let st = cardStates;
        st[id] = false;
        setCardStates(st);
    }

    return (
        <div className="page" id="home">

            <Helmet>
                <title>Tier 33 Productions - Audio and Video Equipment Rental in Philadelphia</title>
                <meta name='description' content='Tier 33 Productions offers full service A/V, lighting, video, pro audio and DJ equipment rentals. Based in Philadelphia, we also serve the regional east coast. Established in 2015, Tier 33 has served as a key component in the local Philadelphia community, providing audio-visual services &amp; equipment to a large array of events and productions, from live concerts &amp; block parties to weddings &amp; corporate gatherings. With over 10 years in the industry our resources are limitless.' />
                <meta property="og:title" content="Tier 33 Productions - Audio and Video Equipment Rental in Philadelphia" />
                <meta property="og:description" content="Tier 33 Productions offers full service A/V, lighting, video, pro audio and DJ equipment rentals. Based in Philadelphia, we also serve the regional east coast. Established in 2015, Tier 33 has served as a key component in the local Philadelphia community, providing audio-visual services &amp; equipment to a large array of events and productions, from live concerts &amp; block parties to weddings &amp; corporate gatherings. With over 10 years in the industry our resources are limitless." />
            </Helmet>

            <Banner>
                <Slider settings={homeCarouselSettings} autoplay={true} autoplaySpeed={5000} speed={2500} pauseOnHover={false} dots={false} arrows={false}>
                    {/*
                    <LazyLoadImg img={`${cdnRoot}/images/pages/home/banner_1.jpg`} thumb={`${cdnRoot}/images/pages/home/banner_1_thumb.jpg`} />
                    <LazyLoadImg img={`${cdnRoot}/images/pages/home/banner_2.jpg`} thumb={`${cdnRoot}/images/pages/home/banner_2_thumb.jpg`} />
                    <LazyLoadImg img={`${cdnRoot}/images/pages/home/banner_3.jpg`} thumb={`${cdnRoot}/images/pages/home/banner_3_thumb.jpg`} />
                    */}
                    <div className="img-lazy loaded"><div className="img full"><img src={`${cdnRoot}/images/pages/home/banner_1.jpg`} loading="lazy" /></div></div>
                    <div className="img-lazy loaded"><div className="img full"><img src={`${cdnRoot}/images/pages/home/banner_2.jpg`} loading="lazy" /></div></div>
                    <div className="img-lazy loaded"><div className="img full"><img src={`${cdnRoot}/images/pages/home/banner_3.jpg`} loading="lazy" /></div></div>
                </Slider>
            </Banner>

            <div className="content pad-half">

                <div className="cards">

                    <div className={"card" + (cardStates['audio'] ? ' show' : '')}
                        onTouchStart={(e) => onCardTouch(e, 'audio')}
                        //onTouchMove={throttle((e) => onCardTouch(e, 'audio'), 50)}
                        onTouchEnd={(e) => onCardTouchEnd(e, 'audio')}>
                        <Link to="/audio">
                            <h2 className="title safari_only">Audio</h2>
                            <div className="overlay">
                                Our team has a vast technical expertise in providing dynamic, immersive audio solutions for your events.
                            </div>
                            <div className="bg">
                                <LazyLoadImg img={`${cdnRoot}/images/pages/home/Audio.jpg`} thumb={`${cdnRoot}/images/pages/home/Audio_thumb.jpg`} alt="Audio Rentals" />
                            </div>
                        </Link>
                    </div>

                    <div className={"card" + (cardStates['dj-backline'] ? ' show' : '')}
                        onTouchStart={(e) => onCardTouch(e, 'dj-backline')}
                        //onTouchMove={throttle((e) => onCardTouch(e, 'dj-backline'), 50)}
                        onTouchEnd={(e) => onCardTouchEnd(e, 'dj-backline')}>
                        <Link to="/dj-backline">
                            <h2 className="title safari_only">DJ Backline</h2>
                            <div className="overlay">
                                We pride ourselves in having a full array of the world’s premium DJ equipment. From digital to analog, hifi and beyond, we’ve got you covered.
                            </div>
                            <div className="bg">
                                <LazyLoadImg img={`${cdnRoot}/images/pages/home/DJ_Backline.jpg`} thumb={`${cdnRoot}/images/pages/home/DJ_Backline_thumb.jpg`} alt="DJ Backline Rentals" />
                            </div>
                        </Link>
                    </div>

                    <div className={"card" + (cardStates['lighting'] ? ' show' : '')}
                        onTouchStart={(e) => onCardTouch(e, 'lighting')}
                        //onTouchMove={throttle((e) => onCardTouch(e, 'lighting'), 50)}
                        onTouchEnd={(e) => onCardTouchEnd(e, 'lighting')}>
                        <Link to="/lighting">
                            <h2 className="title safari_only">Lighting</h2>
                            <div className="overlay">
                                Lighting shapes the world! Our extensive inventory with the industry's most innovative lighting systems will help set your event apart from the rest.
                            </div>
                            <div className="bg">
                                <LazyLoadImg img={`${cdnRoot}/images/pages/home/Lighting.jpg`} thumb={`${cdnRoot}/images/pages/home/Lighting_thumb.jpg`} alt="Lighting Rentals" />
                            </div>
                        </Link>
                    </div>

                    <div className={"card" + (cardStates['video'] ? ' show' : '')}
                        onTouchStart={(e) => onCardTouch(e, 'video')}
                        //onTouchMove={throttle((e) => onCardTouch(e, 'video'), 50)}
                        onTouchEnd={(e) => onCardTouchEnd(e, 'video')}>
                        <Link to="/video">
                            <h2 className="title safari_only">Video</h2>
                            <div className="overlay">
                                Take your event to the next level with video! From projectors to LED video walls, cameras, and more, Tier 33 can provide full service video solutions from start to finish.
                            </div>
                            <div className="bg">
                                <LazyLoadImg img={`${cdnRoot}/images/pages/home/Video.jpg`} thumb={`${cdnRoot}/images/pages/home/Video_thumb.jpg`} alt="Video Rentals" />
                            </div>
                        </Link>
                    </div>

                    <div className={"card" + (cardStates['event-production'] ? ' show' : '')}
                        onTouchStart={(e) => onCardTouch(e, 'event-production')}
                        //onTouchMove={throttle((e) => onCardTouch(e, 'event-production'), 50)}
                        onTouchEnd={(e) => onCardTouchEnd(e, 'event-production')}>
                        <Link to="/event-production">
                            <h2 className="title safari_only">Event Production</h2>
                            <div className="overlay">
                                Tier 33 is a full service production company. We are happy to provide our combined knowledge in all facets of your event from logistics & labor, to design &amp; deployment. Our team can execute skillsets in any department to execute any task!
                            </div>
                            <div className="bg">
                                <LazyLoadImg img={`${cdnRoot}/images/pages/home/Event_Production.jpg`} thumb={`${cdnRoot}/images/pages/home/Event_Production_thumb.jpg`} alt=" Event Production Rentals" />
                            </div>
                        </Link>
                    </div>

                </div>

                <div className="blurb">
                    <p>
                        Tier 33 Productions offers full service A/V, lighting, video, pro audio and DJ equipment rentals. Based in Philadelphia, we also serve the regional east coast. Established in 2015, Tier 33 has served as a key component in the local Philadelphia community, providing audio-visual services &amp; equipment to a large array of events and productions, from live concerts &amp; block parties to weddings &amp; corporate gatherings. With over 10 years in the industry our resources are limitless.
                    </p>
                </div>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div >

    );
};

export default Home;