import LazyLoadImg from 'components/LazyLoadImg';
import React from 'react';

const Banner = (props) => {
    return (
        <div className="banner">

            {props.title &&
                <h1 className="title">
                    {props.title}
                </h1>
            }

            {props.bg && <LazyLoadImg img={props.bg} thumb={props.bgThumb} />}

            {props.children &&
                <div className="children">{props.children}</div>
            }

        </div>

    );
};

export default Banner;
