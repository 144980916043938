import Banner from "components/Banner";
import Button from "components/Button";
import { pageCarouselSettings } from "components/Carousel/carouselSettings";
import LazyLoadImg from "components/LazyLoadImg";
import { cdnRoot } from "config";
import React from 'react';
import { Helmet } from "react-helmet-async";
import Slider from 'react-slick';
import { isMobile } from '../../utils/utils';
import './style.scss';

const Lighting = () => {

    return (
        <div className="page" id="lighting">

            <Helmet>
                <title>Tier 33 - Lighting</title>
                <meta name='description' content="Lighting is what shapes the world and is a vital component in
                        any live event looking to make an impact through the visual arts.
                        Tier 33 only utilizes the most trusted brands in lighting.

                        We offer a great selection of professional event lighting, whether
                        you've got a small dj gig, a large scale corporate event, or anything
                        in between. We provide custom solutions for your event from design
                        to execution, including delivery, set-up and on-site technicians.

                        From concept development, to pre-visualization and programming,
                        we’ve got you covered. Contact us today to see what lighting
                        solutions we can provide for your next event.
                        " />

                <meta property="og:title" content="Tier 33 - Lighting" />
                <meta property="og:description" content="Lighting is what shapes the world and is a vital component in
                        any live event looking to make an impact through the visual arts.
                        Tier 33 only utilizes the most trusted brands in lighting.

                        We offer a great selection of professional event lighting, whether
                        you've got a small dj gig, a large scale corporate event, or anything
                        in between. We provide custom solutions for your event from design
                        to execution, including delivery, set-up and on-site technicians.

                        From concept development, to pre-visualization and programming,
                        we’ve got you covered. Contact us today to see what lighting
                        solutions we can provide for your next event.
                        " />
            </Helmet>

            <Banner title="Lighting" bg={`${cdnRoot}/images/pages/lighting/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/lighting/banner_thumb.jpg`} />

            <div className="content">

                <div className="blurb darker">
                    <p>
                        Lighting is what shapes the world and is a vital component in
                        any live event looking to make an impact through the visual arts.
                        Tier 33 only utilizes the most trusted brands in lighting.
                    </p>

                    <p>
                        We offer a great selection of professional event lighting, whether
                        you've got a small dj gig, a large scale corporate event, or anything
                        in between. We provide custom solutions for your event from design
                        to execution, including delivery, set-up and on-site technicians.
                    </p>

                    <p>
                        From concept development, to pre-visualization and programming,
                        we’ve got you covered. Contact us today to see what lighting
                        solutions we can provide for your next event.
                    </p>
                </div>

                <Slider settings={pageCarouselSettings} autoplay={true} autoplaySpeed={4000} speed={2000} pauseOnHover={false} slidesToShow={isMobile() ? 1 : 2} dots={false} arrows={false}>
                    <div className="carousel-img"><LazyLoadImg img={`${cdnRoot}/images/pages/lighting/Lighting Photo 1.JPG`} thumb={`${cdnRoot}/images/pages/lighting/Lighting Photo 1_thumb.jpg`} /></div>
                    <div className="carousel-img"><LazyLoadImg img={`${cdnRoot}/images/pages/lighting/Lighting Photo 2.JPG`} thumb={`${cdnRoot}/images/pages/lighting/Lighting Photo 2_thumb.jpg`} /></div>
                    <div className="carousel-img"><LazyLoadImg img={`${cdnRoot}/images/pages/lighting/Lighting Photo 3.JPG`} thumb={`${cdnRoot}/images/pages/lighting/Lighting Photo 3_thumb.jpg`} /></div>
                </Slider>

                <div className="sections">

                    <div className="section">
                        <div className="title">
                            Lighting &amp; Special FX Rentals
                        </div>
                        <ul className="list">
                            <li>Spotlights</li>
                            <li>Hazers/Foggers</li>
                            <li>Moving Heads</li>
                            <li>Cryo Cannons</li>
                            <li>Uplights</li>
                            <li>Confetti Cannons</li>
                            <li>LED Strobes</li>
                            <li>Disco Balls</li>
                            <li>Lasers</li>
                        </ul>
                    </div>

                    <div className="section full">
                        <div className="title">
                            Lighting Services
                        </div>
                        <ul className="list">
                            <li>Design &amp; Installation</li>
                            <li>Custom Live Shows</li>
                            <li>Programming Lighting Systems</li>
                        </ul>
                    </div>

                </div>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div>
    );
};

export default Lighting;
