import Banner from 'components/Banner';
import Button from 'components/Button';
import { cdnRoot } from 'config';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import './style.scss';

const Audio = () => {

    return (
        <div className="page" id="audio">

            <Helmet>
                <title>Tier 33 - Audio</title>
                <meta name='description' content='Tier 33 - Audio' />
                <meta property="og:title" content="Tier 33 - Audio" />
                <meta property="og:description" content="Tier 33 - Audio" />
            </Helmet>

            <Banner shiftHeight="-100px" title="Audio" bg={`${cdnRoot}/images/pages/audio/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/audio/banner_thumb.jpg`} />

            <div className="content pad-half">

                <ul className="items">

                    <li className="item">
                        <Link to="/audio/danley">
                            <div className="image">
                                <img src={`${cdnRoot}/images/pages/audio/danley.png`} alt="Danley Audio Rentals" />
                            </div>
                            <div className="title">
                                Danley Speaker Rentals
                            </div>
                            <div className="button solid red">Learn More</div>
                        </Link>
                    </li>

                    <li className="item">
                        <Link to="/audio/qsc">
                            <div className="image">
                                <img src={`${cdnRoot}/images/pages/audio/qsc.png`} alt="QSC Audio Rentals" />
                            </div>
                            <div className="title">
                                QSC Speaker Rentals
                            </div>
                            <div className="button solid red">Learn More</div>
                        </Link>
                    </li>

                </ul>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div>
    );
};

export default Audio;
