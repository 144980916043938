import Banner from 'components/Banner';
import Button from 'components/Button';
import GoogleMap from 'components/GoogleMap';
import { cdnRoot } from 'config';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { isEmail, sendEmail } from 'utils/utils';
import './style.scss';

const SENT_MESSAGE = "Message sent!";

const Contact = () => {
    const [status, setStatus] = useState(undefined);

    const inputName = useRef();
    const inputEmail = useRef();
    const inputSubject = useRef();
    const inputMessage = useRef();

    const sendMessage = async () => {
        try {
            let name = inputName.current.value.trim();
            let email = inputEmail.current.value.trim();
            let subject = inputSubject.current.value.trim();
            let message = inputMessage.current.value.trim();

            if (!name || !email || !subject || !message) return setStatus("Please fill in all fields.");
            if (!isEmail(email)) return setStatus("Please enter a valid email address.");

            setStatus('Sending...');

            let r = await sendEmail(name, email, subject, message);
            console.log(`Send response:`, r)
            if (!r.ok) throw r;

            //console.log(`Response`, name, email, subject, message, r);
            setStatus(SENT_MESSAGE);
        } catch (e) {
            console.log(`Send error:`, e)
            setStatus('An error occurred: ' + JSON.stringify(e));
        }
    }

    return (
        <div className="page" id="contact">

            <Helmet>
                <title>Tier 33 - Contact</title>
                <meta name='description' content='Tier 33 Productions - 1824 Germantown Ave, Philadelphia, PA 19122 - Tel: 570-290-0394' />

                <meta property="og:title" content="Tier 33 - Contact" />
                <meta property="og:description" content="Tier 33 Productions - 1824 Germantown Ave, Philadelphia, PA 19122 - Tel: 570-290-0394" />
            </Helmet>

            <Banner title="Contact Us" bg={`${cdnRoot}/images/pages/contact/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/contact/banner_thumb.jpg`} />

            <div className="content">

                <div className="section location pad">

                    <div className="map">
                        <GoogleMap id="tier33-map" />
                    </div>

                    <div className="address">

                        <div className="info">
                            <div className="header">
                                Main Office
                            </div>
                            <div className="text">
                                Our main office is located in the Fishtown neighborhood
                                of Philadelphia, PA.
                            </div>
                            <div className="text">
                                We primarily serve the Greater New York, NYC, Philadelphia, and Washington D.C. areas.
                            </div>
                        </div>

                        <div className="info">
                            <div className="header office">
                                Tier 33 Productions
                            </div>
                            <div className="text">
                                1824 Germantown Ave<br />
                                Philadelphia, PA 19122<br />
                                <br />
                                Tel: <a href="Tel:570-290-0394">570-290-0394</a>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="section social">
                    <div className="icon fb">
                        <a href="https://www.facebook.com/tier33prod" target="_blank"><img src={`${cdnRoot}/images/icon_fb.svg`} /></a>
                    </div>
                    <div className="icon ig">
                        <a href="https://www.instagram.com/tier33productions/" target="_blank"><img src={`${cdnRoot}/images/icon_ig.svg`} /></a>
                    </div>
                </div>

                <div className="section quote">
                    <div className="header">
                        Interested in a Quote?
                    </div>
                    <div className="subtitle">
                        Give us a call or fill out the form below and we'll get back to you as soon as possible!
                    </div>
                    <div className="form">
                        <form>
                            <div className="row double">
                                <input type="text" placeholder="Your name" name="name" ref={inputName} />
                                <input type="email" placeholder="Your email" name="email" ref={inputEmail} />
                            </div>
                            <div className="row">
                                <input type="text" placeholder="Subject" name="subject" ref={inputSubject} />
                            </div>
                            <div className="row">
                                <textarea placeholder="Message" name="message" ref={inputMessage} />
                            </div>
                            <div className="row btn">
                                <Button onClick={() => sendMessage()} type="solid red">Send Message</Button>
                            </div>
                            <div className={"status" + (status == SENT_MESSAGE ? ' sent' : '')}>
                                {status}
                            </div>
                        </form>
                    </div>
                </div>
            </div >



        </div >
    );
};

export default Contact;
