import Banner from 'components/Banner';
import { cdnRoot } from 'config';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import images from './images';
import './style.scss';

const GALLERY_ROOT = `${cdnRoot}/images/pages/gallery/`;

const Gallery = () => {
    const [galleryImage, setGalleryImage] = useState(undefined);

    return (
        <div className="page" id="gallery">

            <Helmet>
                <title>Tier 33 - Gallery</title>
                <meta name='description' content='Tier 33 - Gallery' />
                <meta property="og:title" content="Tier 33 - Gallery" />
                <meta property="og:description" content="Tier 33 - Gallery" />
            </Helmet>

            <Banner title="Gallery" bg={`${cdnRoot}/images/pages/gallery/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/gallery/banner_thumb.jpg`} />

            <div className="content pad">

                <div className={"popup " + (galleryImage ? 'open' : 'closed')}>
                    <div className="bg" onClick={() => setGalleryImage(undefined)}></div>
                    <div className="close" onClick={() => setGalleryImage(undefined)}>
                        <img src={`${cdnRoot}/images/close_x.svg`} />
                    </div>
                    <div className="inner">
                        <div className="caption">
                            {galleryImage && galleryImage[1]}
                        </div>
                        <div className="image">
                            <div className="loading">Loading...</div>
                            {galleryImage && <a href={`${GALLERY_ROOT}${galleryImage[0]}`} target="_blank"><img src={`${GALLERY_ROOT}${galleryImage[0]}`} /></a>}
                        </div>
                    </div>
                </div>

                <ul className="grid">
                    {images.map((img, i) =>
                        <li key={i} onClick={(e) => setGalleryImage(img)}>
                            <div className="caption">
                                {img[1]}
                            </div>
                            <div className="loading">Loading...</div>
                            <img src={`${GALLERY_ROOT}thumbs/${img[0].replace('.jpg', '.png')}`} />
                        </li>
                    )}
                </ul>

            </div>

        </div>
    );
};

export default Gallery;
