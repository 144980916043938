import Banner from 'components/Banner';
import Button from 'components/Button';
import { cdnRoot } from 'config';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './style.scss';

const About = () => {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) setLoaded(true);
    }, [loaded]);

    return (
        <div className="page" id="about">

            <Helmet>
                <title>Tier 33 - About</title>
                <meta name='description' content='Tier 33 was founded in 2015 by President and Chief Executive Officer,
Keenan O’Connell. While attending Temple University for studies in
business & entrepreneurship in the media industry, Keenan developed
a passion for nightlife. Attending many concerts in the city,
 he began studying the art of show, understanding that behind these
productions, was a team of technicians bringing the audio visual
synchronicities to life.


For years to follow, Keenan began to DJ and produce
events of his own on and off campus. As these events and his collection
of gear grew at an avid pace, Keenan’s studies facilitated this movement
towards creating a business, and Tier 33 was born. Renting out
DJ equipment, speakers, lighting, and DJ services was the start,
but there was still much to learn.


Following his college years, Keenan began his career as an
A/V technician, working for the most prominent production companies
 in Philadelphia. He learned the backend of the business and
began developing skillsets while providing service in the
city’s largest nightclubs, festivals, & concerts.


After nearly a decade in the industry, Tier 33 has proven to be
a key component in the local Philadelphia community. Our goal
is and always will be to amplify the visions & voices of
human beings looking to make an impact through live events.


At Tier 33, we believe a quality live experience lies in the
alignment of the senses. By utilizing the most innovative entertainment
technologies, our highly trained staff can help achieve this to
a professional degree, while meeting your vision in creating an
event to remember. We provide expert audio-visual solutions
for productions of any shape or size. With our service,
we aim to assist in utilizing spaces as catalysts for
entertainment, connection, inspiration & memories.
This is our calling and how we intend to integrate
our energy into the event industry
and the world.' />

                <meta property="og:title" content="Tier 33 - About" />
                <meta property="og:description" content="Tier 33 was founded in 2015 by President and Chief Executive Officer,
Keenan O’Connell. While attending Temple University for studies in
business & entrepreneurship in the media industry, Keenan developed
a passion for nightlife. Attending many concerts in the city,
 he began studying the art of show, understanding that behind these
productions, was a team of technicians bringing the audio visual
synchronicities to life.


For years to follow, Keenan began to DJ and produce
events of his own on and off campus. As these events and his collection
of gear grew at an avid pace, Keenan’s studies facilitated this movement
towards creating a business, and Tier 33 was born. Renting out
DJ equipment, speakers, lighting, and DJ services was the start,
but there was still much to learn.


Following his college years, Keenan began his career as an
A/V technician, working for the most prominent production companies
 in Philadelphia. He learned the backend of the business and
began developing skillsets while providing service in the
city’s largest nightclubs, festivals, & concerts.


After nearly a decade in the industry, Tier 33 has proven to be
a key component in the local Philadelphia community. Our goal
is and always will be to amplify the visions & voices of
human beings looking to make an impact through live events.


At Tier 33, we believe a quality live experience lies in the
alignment of the senses. By utilizing the most innovative entertainment
technologies, our highly trained staff can help achieve this to
a professional degree, while meeting your vision in creating an
event to remember. We provide expert audio-visual solutions
for productions of any shape or size. With our service,
we aim to assist in utilizing spaces as catalysts for
entertainment, connection, inspiration & memories.
This is our calling and how we intend to integrate
our energy into the event industry
and the world." />
            </Helmet>

            <Banner title="About Us" bg={`${cdnRoot}/images/pages/about/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/about/banner_thumb.jpg`} />

            <div className="content">
                <div className="blurb">
                    <p>
                        Tier 33 was founded in 2015 by President and Chief Executive Officer,
                        Keenan O’Connell. While attending Temple University for studies in
                        business & entrepreneurship in the media industry, Keenan developed
                        a passion for nightlife. Attending many concerts in the city,
                        he began studying the art of show, understanding that behind these
                        productions, was a team of technicians bringing the audio visual
                        synchronicities to life.
                    </p>


                    <p>
                        For years to follow, Keenan began to DJ and produce
                        events of his own on and off campus. As these events and his collection
                        of gear grew at an avid pace, Keenan’s studies facilitated this movement
                        towards creating a business, and Tier 33 was born. Renting out
                        DJ equipment, speakers, lighting, and DJ services was the start,
                        but there was still much to learn.
                    </p>


                    <p>
                        Following his college years, Keenan began his career as an
                        A/V technician, working for the most prominent production companies
                        in Philadelphia. He learned the backend of the business and
                        began developing skillsets while providing service in the
                        city’s largest nightclubs, festivals, & concerts.
                    </p>


                    <p>
                        After nearly a decade in the industry, Tier 33 has proven to be
                        a key component in the local Philadelphia community. Our goal
                        is and always will be to amplify the visions & voices of
                        human beings looking to make an impact through live events.
                    </p>


                    <p>
                        At Tier 33, we believe a quality live experience lies in the
                        alignment of the senses. By utilizing the most innovative entertainment
                        technologies, our highly trained staff can help achieve this to
                        a professional degree, while meeting your vision in creating an
                        event to remember. We provide expert audio-visual solutions
                        for productions of any shape or size. With our service,
                        we aim to assist in utilizing spaces as catalysts for
                        entertainment, connection, inspiration & memories.
                        This is our calling and how we intend to integrate
                        our energy into the event industry
                        and the world.
                    </p>
                </div>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div>
    );
};

export default About;
