import Banner from 'components/Banner';
import Button from 'components/Button';
import { cdnRoot } from 'config';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import './style.scss';

const Audiodanley = () => {

    return (
        <div className="page" id="audio-danley">

            <Helmet>
                <title>Tier 33 - Audio - Danley Speaker Rentals</title>
                <meta name='description' content='Tier 33 - Danley Speaker Rentals - Danley Sound Labs built it’s global reputation on designing the most technologically innovative audio products on the market. Danley’s powerful systems generate crystal clear sound and are the ideal choice in nightclubs, arenas, festivals, and touring clients looking to stand out from the rest.' />
                <meta property="og:title" content="Tier 33 - Audio - Danley Speaker Rentals" />
                <meta property="og:description" content="Tier 33 - Danley Speaker Rentals - Danley Sound Labs built it’s global reputation on designing the most technologically innovative audio products on the market. Danley’s powerful systems generate crystal clear sound and are the ideal choice in nightclubs, arenas, festivals, and touring clients looking to stand out from the rest." />
            </Helmet>

            <Banner title="Danley Speaker Rental" bg={`${cdnRoot}/images/pages/audio/danley/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/audio/danley/banner_thumb.jpg`} />

            <div className="content">

                <div className="blurb darker">
                    <p>
                        Danley Sound Labs built it’s global reputation on designing
                        the most technologically innovative audio products on the
                        market. Danley’s powerful systems generate crystal
                        clear sound and are the ideal choice in nightclubs, arenas,
                        festivals, and touring clients looking to stand out from the rest.
                    </p>

                    <p>
                        Give us a call and we can discuss a Danley Rental solution
                        for your next event: 570-290-0394
                    </p>
                </div>

                <ul className="items pad">

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/audio/danley/Danley SH46 Photo .png`} />
                        </div>
                        <div className="brand-logo">
                            <img src={`${cdnRoot}/images/pages/audio/danley_logo.svg`} />
                        </div>
                        <div className="title">
                            SH46
                        </div>
                        <div className="description">
                            This Synergy Horn Full-Range Loudspeaker provides unparalleled sound despite an impressively small footprint. The SH46 has incredible fidelity, pattern control, and impressive magnitude/phase response.
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/audio/danley/Danley DBH218 Photo.png`} />
                        </div>
                        <div className="brand-logo">
                            <img src={`${cdnRoot}/images/pages/audio/danley_logo.svg`} />
                        </div>
                        <div className="title">
                            DBH218
                        </div>
                        <div className="description">
                            This Dual 18” Bass Horn Subwoofer also known as
                            “The Thumper” provides a punchy low-end like no other.
                            This powerful subwoofer is great in club and music
                            environments.
                        </div>
                    </li>

                    <li className="item">
                        <div className="image">
                            <img src={`${cdnRoot}/images/pages/audio/danley/Danley Amplifier DNA20KPRO photo.png`} />
                        </div>
                        <div className="brand-logo">
                            <img src={`${cdnRoot}/images/pages/audio/danley_logo.svg`} />
                        </div>
                        <div className="title">
                            DNA20KPRO
                        </div>
                        <div className="description">
                            This truly revolutionary amplifier platform provides 20,000
                            watts RMS total output, precise digital signal processing,
                            a logical front panel user interface and a powerful
                            ethernet based remote control.
                        </div>
                    </li>

                </ul>

            </div>

            <div className="contact-banner">
                <Button to="/contact" type="outline red">Contact Us Today</Button>
            </div>

        </div>
    );
};

export default Audiodanley;
