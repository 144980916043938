import Banner from 'components/Banner';
import { cdnRoot } from 'config';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import './style.scss';

const Clients = () => {

    return (
        <div className="page" id="clients">

            <Helmet>
                <title>Tier 33 - Clients</title>
                <meta name='description' content='Tier 33 - Clients' />

                <meta property="og:title" content="Tier 33 - Clients" />
                <meta property="og:description" content="Tier 33 - Clients" />
            </Helmet>

            <Banner title="Clients" bg={`${cdnRoot}/images/pages/clients/banner.jpg`} bgThumb={`${cdnRoot}/images/pages/clients/banner_thumb.jpg`} />

            <div className="content pad">

                <ul className="grid">
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Steez Promo Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Subsurface Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Boiler Room Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Making Time Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/PEX Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/WOW Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Spirits Up Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Sylo Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/BWC Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Goodie Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/The Vza Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/WKDU Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Unlocked Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Aspire Higher Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Impressions Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Sic Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Emby Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Flaunt Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Vesper Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Pamplemousse Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Say Less Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Grombacher Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/FrogDog Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Fold Theory Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Departed Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Ray Philly Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/W Philadelphia Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Qunify Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Samsung Logo.png`} /></li>
                    <li><img src={`${cdnRoot}/images/pages/clients/logos/Pizza Logo.png`} /></li>
                </ul>

            </div>

        </div>
    );
};

export default Clients;
