import { cdnRoot } from "config";
import * as React from "react";
import { Link } from 'react-router-dom';
import './style.scss';

const SiteFooter = (activePage: any = undefined) => {

    return (
        <div id="site-footer">

            <div className="cols">

                <div className="col left">

                    <Link to="/" className="logo">
                        <img src={`${cdnRoot}/images/logos/logo_stencil_white.png`} />
                    </Link>

                    <div className="section contact">
                        <h2>Contact Information</h2>
                        <ul className="items">
                            <li className="item"><div>1824 Germantown Ave.<br />Philadelphia, Pennsylvania</div></li>
                            <li className="item"><span>Call</span>&nbsp;&nbsp;<a href="Tel:570-290-0394">570-290-0394</a></li>
                            <li className="item social">
                                <a href="https://www.facebook.com/tier33prod" target="_bkank"><img src={`${cdnRoot}/images/icon_fb.svg`} /></a>
                                <a href="https://www.instagram.com/tier33productions" target="_bkank"><img src={`${cdnRoot}/images/icon_ig.svg`} /></a>
                            </li>
                        </ul>
                    </div>

                </div>

                <div className="col right">

                    <div className="section">
                        <h2>Our Services</h2>
                        <ul className="items">
                            <li><Link to={"/audio"}>Audio</Link></li>
                            <li><Link to={"/dj-backline"}>DJ Backline</Link></li>
                            <li><Link to={"/lighting"}>Lighting</Link></li>
                            <li><Link to={"/video"}>Video</Link></li>
                            <li><Link to={"/event-production"}>Event Production</Link></li>
                        </ul>
                    </div>

                    <div className="section">
                        <h2>Our Company</h2>
                        <ul className="items">
                            <li><Link to={"/about"}>About</Link></li>
                            <li><Link to={"/clients"}>Clients</Link></li>
                            <li><Link to={"/gallery"}>Gallery</Link></li>
                            <li><Link to={"/contact"}>Contact Us</Link></li>
                        </ul>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default SiteFooter;
