import SiteFooter from 'components/SiteFooter';
import SiteHeader from 'components/SiteHeader';
import Audio from 'pages/Audio';
import AudioDanley from 'pages/Audio/Danley';
import AudioQSC from 'pages/Audio/QSC';
import Clients from 'pages/Clients';
import Contact from 'pages/Contact';
import DjBackline from 'pages/DjBackline';
import EventProduction from 'pages/EventProduction';
import Gallery from 'pages/Gallery';
import Lighting from 'pages/Lighting';
import PageNotFound from 'pages/NotFound';
import Video from 'pages/Video';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet, RouterProvider, createBrowserRouter, useLocation } from "react-router-dom";
import About from '../../pages/About';
import Home from '../../pages/Home';
import './style.scss';

const AppShell = (props) => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();

    const helmetContext = {};
    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        setTimeout(() => {
            // Todo: add this to component/plugin.
            const isChrome = !!window.chrome;
            const body = document.getElementsByTagName('body')[0];
            body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            if (navigator?.userAgent && body) {
                if (navigator.userAgent.indexOf('Mac OS X') != -1) {
                    body.classList.add("mac");
                } else {
                    body.classList.add("pc");
                }

                if (isChrome) body.classList.add("chrome");
            }

        }, 0);
    }, [pathname]);

    return (
        <div className="app-shell">
            <HelmetProvider context={helmetContext}>
                <SiteHeader activePage={undefined} />
                <Outlet />
                <SiteFooter />
            </HelmetProvider>
        </div>
    )
}

let router = createBrowserRouter([
    {
        path: "/",
        element: <AppShell />,
        children: [
            {
                path: "/",
                loader: () => ({ message: "Hello!" }),
                Component() {
                    return <Home />;
                },
            },
            {
                path: "/about",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <About />;
                },
            },
            {
                path: "/audio",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <Audio />;
                },
            },
            {
                path: "/audio/qsc",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <AudioQSC />;
                },
            },
            {
                path: "/audio/danley",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <AudioDanley />;
                },
            },
            {
                path: "/dj-backline",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <DjBackline />;
                },
            },
            {
                path: "/lighting",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <Lighting />;
                },
            },
            {
                path: "/video",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <Video />;
                },
            },
            {
                path: "/event-production",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <EventProduction />;
                },
            },
            {
                path: "/gallery",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <Gallery />;
                },
            },
            {
                path: "/clients",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <Clients />;
                },
            },
            {
                path: "/contact",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <Contact />;
                },
            },
            {
                path: "*",
                loader: () => ({ message: "Loading..." }),
                Component() {
                    return <PageNotFound />;
                },
            },
        ],
    }
]);

const App = () => {
    const activePage = undefined; //[activePage, setActivePage] = useState(undefined);

    return (<div id="app">

        <RouterProvider router={router} fallbackElement={<p>Loading...</p>}>
        </RouterProvider>

    </div>);
}

export default App;

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}