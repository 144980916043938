import React, { useEffect, useState } from 'react';

const MARKER_IMG = 'https://tier33.com/images/map_icon.png';
const MARKER_POSITION = { lat: 39.979272711789925, lng: -75.14370594549568 };

const GoogleMap = ({ id, }) => {
    const [glibLoaded, setGlibLoaded] = useState(false);
    const [marker, setMarker] = useState(undefined);
    const [map, setMap] = useState(undefined);

    useEffect(() => {
        checkGlib();
    }, []);

    useEffect(() => {
        if (glibLoaded) {
            initMap();
        }
    }, [glibLoaded]);

    const checkGlib = () => {
        //@ts-ignore
        if (typeof google == 'undefined') {
            setTimeout(checkGlib, 500);
        } else {
            //@ts-ignore
            if (google?.maps && !glibLoaded) setGlibLoaded(true);
        }
    };

    // Initialize and add the map
    const initMap2 = async () => {
        // The location of Uluru
        const position = { lat: -25.344, lng: 131.031 };

        // Request needed libraries.
        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

        // The map, centered at Uluru
        const map = new Map(
            document.getElementById(id) as HTMLElement,
            {
                zoom: 4,
                center: position,
                mapId: 'a2ac52156000df9f',
            }
        );

        // The marker, positioned at Uluru
        const marker = new AdvancedMarkerElement({
            map: map,
            position: position,
            title: 'Uluru'
        });
    }

    // Initialize and add the map
    const initMap = async () => {
        const position = MARKER_POSITION;

        // Request needed libraries.
        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        //@ts-ignore
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

        let _map = new Map(document.getElementById(id) as HTMLElement, {
            mapId: 'a2ac52156000df9f',
            zoom: 16,
            center: position,
            //mapTypeId: google.maps.MapTypeId.ROADMAP,
            draggable: true,
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            scrollwheel: true
        });

        const markerImg = document.createElement('img');
        markerImg.src = MARKER_IMG;

        const _marker2 = new AdvancedMarkerElement({
            map: _map,
            position,
            title: 'Tier 33 Productions',
            content: markerImg
        });

        // const _marker2 = new google.maps.Marker({
        //     position,
        //     icon: MARKER_IMG,
        //     map,
        // });

        // setMap(_map);
        // setMarker(_marker2);
    }

    return (
        <div className="google-map" id={id}>
        </div>
    );

}

export default GoogleMap;