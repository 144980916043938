import * as React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {

    return (
        <div className={"button " + (props.type || "")}>
            <div onClick={props.onClick ? props.onClick : undefined}>
                {props.to ?
                    <Link to={props.to}>{props.children}</Link>
                    :

                    <span>{props.children}</span>
                }
            </div>
        </div>
    );
};

export default Button;
